export function trackScrollDepth() {
  _saveScrollDepth();
  _onDebouncedScroll(_saveScrollDepth, 200);
}

function _onDebouncedScroll(callback, debouncePeriod) {
  let scrollTimeout;

  document.addEventListener('scroll', (event) => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      callback();
    }, debouncePeriod);
  });
}

function _saveScrollDepth() {
  const scrollDepth = _calculateScrollDepth();

  if (scrollDepth !== null) {
    gtag('event', 'scroll_threshold', {
      'value': scrollDepth,
      'event_category': 'engagement',
      'event_label': scrollDepth * 100 + '%'
    });
  }
}

function _calculateScrollDepth() {
  const scrollYDepth = (document.documentElement.scrollTop + document.documentElement.clientHeight)
    / document.documentElement.scrollHeight;

  const scrollDepthThresholds = [.25, .5, .75, .875];

  let thresholdCrossedIndex = -1;

  scrollDepthThresholds.some((threshold) => {
    if (scrollYDepth < threshold) {
      return true;
    }

    thresholdCrossedIndex++;
  });

  thresholdCrossedIndex = Math.min(thresholdCrossedIndex, scrollDepthThresholds.length);

  return thresholdCrossedIndex >= 0 ? scrollDepthThresholds[thresholdCrossedIndex] : null;
}
