$(() => {
  $('.sidebar-menu .toggle-menu').on('click', (e) => {
    event.preventDefault();
    const menuRef = $(e.currentTarget).closest('li').children('[data-sidebar-tree]');

    if (menuRef.parent('li').hasClass('active')) {
      menuRef.slideUp();
      menuRef.parent('li').removeClass('active');
    } else {
      menuRef.slideDown();
      menuRef.parent('li').siblings().children('ul').slideUp();
      menuRef.parent('li').siblings().removeClass('active');
      menuRef.parent('li').addClass('active');
    }
  });

  saveSidebarPositionBeforeUnload();
  mobileSidebar();
});

function saveSidebarPositionBeforeUnload() {
  if(!localStorage) {
    return;
  }
  $('.sidebar-menu a').click(function(){
    let scrollValue = $(this).closest('.sidebar-wrapper>.sidebar-menu').offset().top;
    localStorage.setItem('sidebar-scroll-value', scrollValue);
  });
}

function mobileSidebar() {
  let $docLeft = $('.doc-left');
  let $togglerSidebar = $('.navbar-toggler-sidebar');
  let width = $(window).width();
  if (width <= 991){
    $togglerSidebar.attr("class","navbar-toggler-sidebar collapsed");
    $docLeft.attr("class","doc-left collapse");
  } else {
    $togglerSidebar.attr("class","navbar-toggler-sidebar");
    $docLeft.attr("class","doc-left collapse show");
  }
}
