let userIP;

$(() => {
  _setUserIP();
  _submitFeedback();
  _closeFeedbackPopup();
});

function _setUserIP() {
  $.getJSON("https://api.ipify.org?format=json", function(data) {
    userIP = data.ip;
  });
}

function _submitFeedback() {
  $('.feedback-form').on('submit', function(e) {
    e.stopImmediatePropagation();
    _setSubmittingStatus();

    const formData = new FormData();
    
    const feedbackFieldName = 'feedback';
    const feedbackValues = [];

    $.each($(this).serializeArray(), function(i, field) {
      if (field.name === feedbackFieldName) {
        feedbackValues.push(field.value);
      } else {
        formData.append(field.name, field.value || '');
      }
    });

    formData.append(feedbackFieldName, feedbackValues.join(', '));
    formData.append('pageUrl', _getPagePath());
    formData.append('pageTitle', _getPageTitle());
    formData.append('userIP', userIP);
    formData.append('dateTime', _getFormattedCurrentTimestamp());

    $.ajax({
      method: 'POST',
      url: window.zapierFeedbackURL,
      data: formData,
      contentType: false,
      processData: false,
      enctype: 'multipart/form-data',
      success: () => {
        _setSubmittingStatus(false)
        $('.feedback-container, .feedback-popup-anchor').addClass('d-none');
        _clearFeedbackForm();
      }
    });
  });
}

function _setSubmittingStatus(submitting = true) {
  if (submitting) {
    $('.feedback-submit').text('Submitting').attr('disabled', true);
  } else {
    $('.feedback-submit').text('Submit Feedback').removeAttr('disabled');
  }
}

function _getPagePath() {
  const url = new URL(window.location.href);
  return url.pathname || '';
}

function _getPageTitle() {
  return window.SITE_DATA.title || '';
}

function _getFormattedCurrentTimestamp() {
  const currentTimestamp = new Date();
  return `${ currentTimestamp.getFullYear() }-${ currentTimestamp.getMonth() + 1 }-${ currentTimestamp.getDate() } ${ currentTimestamp.getHours() }:${ currentTimestamp.getMinutes() }:${ currentTimestamp.getSeconds() }`;
}

function _closeFeedbackPopup() {
  $('.feedback-close').on('click', function() {
    $('.feedback-container, .feedback-popup-anchor').addClass('d-none');
    _clearFeedbackForm();
  });
}

function _clearFeedbackForm() {
  $('.feedback-form input, .feedback-form textarea')
    .val('')
    .prop('checked', false)
    .prop('selected', false);
}
