import 'bootstrap';

import './table-of-contents';
import ScrollToAnchorOnLoad from './scroll-to-anchor-on-load';
import './header-anchor';
import './algolia-search';
import './search-highlight';
import './sidebar';
import './sidebar-new-badge';
import './article-feedback';
import './chat-support';
import './image-zoom';
import './section-contents';
import './code-editor';
import './clipboard-copy';
import './notes-container';
import { trackScrollDepth } from "./tracking";
import { handleCollapsibleContentToggle } from "./collapsible-content";
import './scroll-to-top';
import './click-tracking';

import './tabs';

import './scroll-to-highlight';
import './upcoming-features-filter-dropdown';
import './feedback-popup';
import './glossary-tooltip';
import './infographics';
import '../_scss/main.scss';

$(document).ready(function () {
  $('body').addClass('smooth-scroll');

  new ScrollToAnchorOnLoad().registerHandlers();
  $(document).trigger('page.didUpdate');

  $('.tooltip-show').tooltip();

  handleCollapsibleContentToggle();
  
});

trackScrollDepth();
