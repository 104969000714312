$(document).ready(function($) {
  const getSearchResultTerm = localStorage.getItem('search_result_term');

  if (getSearchResultTerm) {
    setTimeout(expandIfCollapsed, 500);

    localStorage.removeItem('search_result_term');

    const searchResultTerm = getSearchResultTerm.replace(/_/g," ").replace(/=/g,"_");

    const searchedElementsList = $('div.inner-container div.header-anchor-text, p, td, ul.sub-section-contents a:contains("' + searchResultTerm + '")').filter(
      function(i) {
        return $(this).text().indexOf(searchResultTerm) >= 0;
      }
    )

    if (searchedElementsList && searchedElementsList.length > 0) {
      const searchedElement = searchedElementsList.eq(0);

      if (searchedElement) {
        const searchedElementOffset = searchedElement.offset().top - 100;
        $('html, body').animate({
          scrollTop: searchedElementOffset
        }, 500);
      }
    }
  }

  function expandIfCollapsed() {
    $('.inner-container').find('.collapsible-content').css("display", "block");
    $('.inner-container').find('.collapsible-toggle').removeClass('collapsed').addClass('hevo-minus').removeClass('hevo-plus');

    $('.inner-container .item-has-child').find('.toggle-menu').removeClass('collapsed');
    $('.inner-container .sub-section-contents').find('.collapsed-child').addClass('show');
  }

  const searchedTerm = localStorage.getItem('search_term');
  if (searchedTerm) {
    localStorage.removeItem('search_term');
    $('.doc-section').highlight( searchedTerm );
  }

});
