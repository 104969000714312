import { expandIfCollapsed, preventParentScroll } from "./functions";

const tocSelector = ".toc-wrapper ul.section-nav";
const tocContainer$ = $(tocSelector);
const tocContainerHeight = tocContainer$.height();

$(() => {
  let lastMatchH2 = null,
    lastMatchH3 = null;

  const setActiveAnchorsInToc = () => {
    let currentMatchH2 = _findMatchingHeader("h2"),
      currentMatchH3 = _findMatchingHeader("h3");

    // update the matching h2
    if (currentMatchH2 && currentMatchH2 !== lastMatchH2) {
      lastMatchH2 = currentMatchH2;

      _setActiveTocLink("h2", currentMatchH2);
    }

    // if h3 is contained in current h2
    // then only we show it
    if (
      currentMatchH2 &&
      currentMatchH3 &&
      $("#" + currentMatchH2).offset().top >
        $("#" + currentMatchH3).offset().top
    ) {
      currentMatchH3 = null;
    }

    if (currentMatchH3 !== lastMatchH3) {
      lastMatchH3 = currentMatchH3;

      _setActiveTocLink("h3", currentMatchH3);
    }
  };

  const updateActiveTOCAnchors = () => {
    setActiveAnchorsInToc();
    _scrollToActiveTocLink(); 
    _setClassForStickySection();
  };

  $(window).on("scroll", updateActiveTOCAnchors);

  $(window).on("popstate", updateActiveTOCAnchors);

  $(window).on("hashchange", (e) => {
    e.stopPropagation();

    if (window.location.hash) {
      const hashId = window.location.hash;
      expandIfCollapsed(hashId);

      const el$ = $(window.location.hash).get(0);

      if (el$) {
        el$.scrollIntoView();
        updateActiveTOCAnchors();
      }
    }
  });

  const addClassToAnchors = () => {
    $(".doc-toc a").each(function() {
      $(this).addClass("nav-link");

      // Get Id of header
      const href = $(this).attr("href"),
        header$ = $(":header" + href);

      // Add Step # in ToC if header has step metadata
      if (header$.hasClass("step")) {
        const step = $(".step").index(header$) + 1;

        $(this).prepend(`Step ${step}. `);
      }
    });
  };

  const updateViewType = () => {
    let $indexMainWrap = $(".toc-wrapper");
    let $indexTitle = $(".toc-wrapper h5");
    let $indexUl = $(".toc-wrapper .doc-toc");
    let width = $(window).width();
    if (width <= 767) {
      $indexMainWrap.addClass("mobile");
      $indexUl.hide();
      if (!$(".index-toggle").length) {
        //it does!
        $indexTitle.append(
          "<span class='hevo-docs-icon hevo-dropdown-arrow index-toggle'></span>"
        );
      }
    } else {
      $indexMainWrap.removeClass("mobile");
      $indexUl.show();
      $indexMainWrap.find(".index-toggle").remove();
    }
  };

  addClassToAnchors();
  updateViewType();

  $(window).resize(() => updateViewType);

  // Toggle the ToC when clicked on header
  $(".toc-wrapper h5").click(function() {
    $(this)
      .closest(".toc-wrapper")
      .find(".doc-toc")
      .slideToggle();
  });

  tocContainer$.on("scroll", function() {
    _scrollWindowOnTocScroll();
  });

  preventParentScroll(tocContainer$);
});

/**
 * find the current header in viewport
 * based on scroll position
 *
 * @param {'h2'|'h3'} elemType
 * @returns id of the matched element
 */
function _findMatchingHeader(elemType) {
  const scrollTop = $(window).scrollTop() + 40;
  let currMatch;

  $(elemType).each(function() {
    const id = $(this).attr("id");

    // not considering elems not visible in the doc
    if (
      id &&
      scrollTop >= $(this).offset().top &&
      $(`.doc-toc a[href="#${id}"]`).length &&
      $(this).is(":visible")
    ) {
      currMatch = id;
    }
  });

  return currMatch;
}

/**
 * mark the appropriate <a> tag
 * in ToC as active
 *
 * @param {'h2'|'h3'} elemType
 * @param {string} currMatch
 */

function _setActiveTocLink(elemType, currMatch) {
  $(".doc-toc li.toc-" + elemType).each(function() {
    const hrefLink = $(this).children("a:first");

    if (hrefLink.attr("href") === `#${currMatch}`) {
      hrefLink.addClass("active");
    } else {
      hrefLink.removeClass("active");
    }
  });
}

function _scrollToActiveTocLink() {
  const activeLink$ = $(".doc-toc > li.nav-item > a.nav-link.active");

  if (activeLink$.length) {
    const activeSection$ = activeLink$.parent('li.nav-item');
    
    if (activeSection$.length) {
      const activeSectionTop = activeSection$.position().top;
      const activeSectionHeight = activeSection$.outerHeight();

      if (
        activeSectionTop < 0 ||
        activeSectionTop + activeSectionHeight > tocContainerHeight
      ) {
        const tocContainerTop = tocContainer$.scrollTop();

        tocContainer$.scrollTop(
          tocContainerTop + activeSectionTop - tocContainerHeight / 2 + activeSectionHeight / 2
        );
      }
    }
  }
}

function _setClassForStickySection() {
  const stickySection$ = $(".sticky-section-wrapper");
  if (stickySection$.length) {
    if (window.scrollY > 395) {
      stickySection$.addClass("sticky");
    } else {
      stickySection$.removeClass("sticky");
    }
  }
}

function _scrollWindowOnTocScroll() {
  const windowScrollTop = $(window).scrollTop(),
    windowHeight = $(window).height();

  const documentHeight = $(document).height();

  // taking this element as scroll anchor
  const anchorOffsetTop = $(".row").offset().top;

  if (windowScrollTop === 0) {
    $("html, body").animate({
      scrollTop: anchorOffsetTop
    }, 500);
  }

  if (windowScrollTop + windowHeight >= documentHeight) {
    $("html, body").animate({
      scrollTop: windowScrollTop - anchorOffsetTop
    }, 500);
  }
}
