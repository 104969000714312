$(() => {
  $('.sub-section-contents .hevo-dropdown-arrow').on('click', (e) => {
    event.preventDefault();
  });

  appendClass();
});

const LI_STARTING_TEXT = "Articles in this section";

function appendClass() {
  const ul$ = $('ul.section-contents');
  const parent$ = ul$.parent();
  const firstChild$ = parent$.children().first();

  const li$ = ul$.children('li').first();
  const subUl$ = li$.children('ul.sub-section-contents.no-bullets').first();

  // if first child of doc-section is not ul.section-contents and
  // ul.sub-section-contents.no-bullets exist inside li and
  // li text doesn't start with Articles in this section
  if (!firstChild$.is(ul$) && subUl$.length && !li$.text().startsWith(LI_STARTING_TEXT)) {
    subUl$.removeClass('no-bullets').addClass('with-bullets');
  }
}
