/**
 * @typedef {('up'|'down')} VoteEnum
 */

let loading = false;

/** @type {string} */
let userIP;

$(() => {
  _setUserIP();
  _handleVoteClick();
});

function _setUserIP() {
  $.getJSON("https://api.ipify.org?format=json", function(data) {
    userIP = data.ip;
  });
}

function _handleVoteClick() {
  $('.article-feedback-btn').on('click', function(e) {
    if (!$(this).hasClass('feedback-given')) {
      _saveVote($(e.currentTarget).data('vote'));
    }
  });
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _saveVote(vote) {
  if (loading) {
    return;
  }

  loading = true;

  _updateSection(vote);
  _recordVote(vote);
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _recordVote(vote) {
  const payload = {
    event: 'Page Feedback',
    properties: {
      vote: vote === 'up' ? 1 : -1,
      title: window.SITE_DATA.title,
      path: window.location.pathname,
      user_ip: userIP
    }
  };

  $.ajax({
    method: 'POST',
    url: window.SITE_DATA.feedbackURL,
    data: JSON.stringify(payload),
    dataType: 'json',
    contentType: "application/json; charset=utf-8",
    success: () => {
      loading = false;
    },
    error: () => {
      loading = false;
    }
  });
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _updateSection(vote) {
  _highlightVote(vote);

  _renderContactSupport(vote);
  _updateScrollTopStyling(vote);
  _renderFeedbackPopup(vote);
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _highlightVote(vote) {
  $(`.article-feedback-btn`).removeClass('feedback-given');
  $(`.article-feedback-btn[data-vote="${vote}"]`).addClass('feedback-given');
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _renderContactSupport(vote) {
  const articleFeedbackContainer$ = $('.article-feedback-success-container');

  if (vote === 'up' && articleFeedbackContainer$.hasClass('d-none')) {
    const copyEmail$ = `<button
      class="font-weight-semi-bold email-copy" 
      data-placement="bottom" 
      data-original-title="Copied to Clipboard!" 
      data-clipboard-text="docs@hevodata.com">
      docs@hevodata.com <i class="hevo-docs-icon hevo-copy"></i>
    </button>`;

    const supportTemplate = 'Thank you for your feedback. For any suggestions, please write to us at ' + copyEmail$;
    articleFeedbackContainer$.html(supportTemplate).removeClass('d-none');
  }

  if (vote === 'down' && !articleFeedbackContainer$.hasClass('d-none')) {
    articleFeedbackContainer$.addClass('d-none');
  }
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _updateScrollTopStyling(vote) {
  const scrollTopBtn$ = $(".scroll-top-btn");

  if (vote === 'up') {
    const scrollTopOffsetBottom = $('.footer-dash').height() + (12 * 3);
    scrollTopBtn$.css('bottom', scrollTopOffsetBottom + 'px');
  } else {
    scrollTopBtn$.css('bottom', 60 + 'px');
  }
}

/**
 * @param {VoteEnum} vote
 * @private
 */
function _renderFeedbackPopup(vote) {
  const feedbackSelectors$ = $('.feedback-container, .feedback-popup-anchor');

  if (vote === 'down' && feedbackSelectors$.hasClass('d-none')) {
    feedbackSelectors$.removeClass('d-none');
  } else if (vote === 'up' && !feedbackSelectors$.hasClass('d-none')) {
    feedbackSelectors$.addClass('d-none');
    _clearFeedbackForm();
  }
}

function _clearFeedbackForm() {
  $('.feedback-container .feedback-form input, .feedback-container .feedback-form textarea')
    .val('')
    .prop('checked', false)
    .prop('selected', false);
}
