import { collapseAllExpandedSections, collapseExpandedSection, expandCollapsedSection } from "./functions";

export function handleCollapsibleContentToggle(collapseRestOfTheHeaders = false) {
  $(".collapsible-toggle").on('click', function() {
    if ($(this).hasClass('collapsed')) {
      if (collapseRestOfTheHeaders) {
        collapseAllExpandedSections();
      }

      expandCollapsedSection(this);
    }

    else {
      collapseExpandedSection(this);
    }
  });
}
