(function ($) {
  $(document).ready(function () {
    var $scheduleDemoButton = $('#navbar-schedule-demo, #sidebar-schedule-demo');
    handleClickTracking($scheduleDemoButton, 'Schedule demo');
  
    var $hevoBanner = $('#hevo_banner, #hevo_footer_banner');
    handleClickTracking($hevoBanner, 'Hevo Banner');
  });

  // Reusable click event handling function
  function handleClickTracking($selected_element, eventCategory) {
    $selected_element.on('click', function (event) {
      var buttonId = $(this).attr('id');
      var eventLabel = ' Click - ' + buttonId;
      sendGtagEvent(eventCategory, eventLabel);
    });
  }

  // Send gtag event function
  function sendGtagEvent(eventCategory, eventLabel) {
    if (typeof gtag === 'function') {
      gtag('event', 'click', {
        'event_category': eventCategory,
        'event_label': eventLabel
      });
    }
  }
})($);
  