import ClipboardJS from "clipboard";

$(() => {
  _handleCodeEditorCopy();
});

function _handleCodeEditorCopy() {
  let snippets = document.querySelectorAll('.doc-section div.highlight');

  [].forEach.call(snippets, function(snippet) {
    snippet.firstChild.insertAdjacentHTML(
      'beforebegin',
      '<button title="Copied!" data-placement="left" class="btn-clipboard hevo-copy" data-clipboard-snippet data-trigger="manual"></button>'
    );
  });

  $('.doc-section .highlight').hover(function() {
    const $btn = $(this).find('.btn-clipboard');
    $btn.addClass('visible');

  }, function() {
    const $btn = $(this).find('.btn-clipboard');
    $btn.removeClass('visible');
    $btn.tooltip('hide');
  });

  let clipboardSnippets = new ClipboardJS('[data-clipboard-snippet]', {
    target: function(trigger) {
      return trigger.nextElementSibling;
    }
  });

  clipboardSnippets.on('success', function(e) {
    e.clearSelection();
    $(e.trigger).tooltip('show');
  });
}
