import ClipboardJS from "clipboard";

$(() => {
  handleClipboardCopy('email-copy');
  handleClipboardCopy('header-anchor');
});

/**
 * @param {string} className
 */
export function handleClipboardCopy(className) {
  const clipboard = new ClipboardJS(`.${className}`);
  let tooltipTrigger;

  clipboard.on('success', function(e) {
    e.clearSelection();

    $(e.trigger).tooltip({
      trigger: 'manual',
    }).tooltip('show');

    tooltipTrigger = $(e.trigger);
  });

  $(document).on('mouseout', `.${className}`, function() {
    if (tooltipTrigger) {
      tooltipTrigger.tooltip('hide');
    }
  });
}

