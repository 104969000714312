$(document).on('page.didUpdate', function() {
  $('h1,h2,h3,h4,h5,h6').each((i, el) => {
    const $el = $(el);
    const id = $el.attr('id');

    if (!id) {
      return;
    }

    let pathname = window.location.pathname;
    if (pathname === '/destinations/data-warehouses/google-bigquery/bigquery2/') {
      pathname = '/destinations/data-warehouses/google-bigquery/';
    }

    if (pathname === '/destinations/data-warehouses/snowflake/snowflake-2/') {
      pathname = '/destinations/data-warehouses/snowflake/';
    }
    
    const pageURL = `https://${ __DOCS_URL__ }${ pathname }#${ id }`;

    // This event gets also fired whenever history.replaceState is called. In
    // that case we do not want to prepend multiple anchors.
    if ($('.anchor', $el).length > 0) {
      return;
    }

    const $linkText = $(`<div class="header-anchor-text"><div>${ $el.html() }</div></div>`);

    const hideCopyLink = window.unpublished || $el.hasClass('hide-copy-link');
    if (!hideCopyLink) {
      const $link = $(`
        <button
          class="header-anchor copy-text" 
          aria-hidden="true"
          data-placement="right"
          data-original-title="Copied to Clipboard!" 
          data-clipboard-text="${pageURL}">
          <span class="hevo-docs-icon hevo-permalink"></span>
        </button>
      `);

      $linkText.append($link);
    }

    const $headerContainer = $(`<div class="header-anchor-container"></div>`);
    $headerContainer.append($linkText);

    // If header has step class then add it before the text class
    const stepClass = $el.hasClass('step');
    if (stepClass) {
      const step = $('.step').index($el) + 1;

      const $stepDiv = $(`<span class="badge header-step">Step ${step}</span>`);

      $headerContainer.prepend($stepDiv);
    }

    if ($el.hasClass('collapsible-section')) {
      const $dropdownIcon = $(`<span class='hevo-docs-icon hevo-plus collapsible-toggle collapsed'></span>`);
      
      $headerContainer.append($dropdownIcon);
      $el.next(".collapsible-content").hide();
    }

    $el.html($headerContainer);
  });
});
