import instantsearch from 'instantsearch.js';
import {connectHits, connectSearchBox} from 'instantsearch.js/es/connectors';
import {getSearchInput} from './search';

$(function () {
  let refineRef = () => {};

  const refine = (query) => {
    refineRef(query);
  }

  const { getCurrentSearchTab, renderHits } = getSearchInput({
    refine
  });

  const search = instantsearch({
    ...(window.ALGOLIA || {}),
    appId: 'HBAU4MYQP6',
    apiKey: '218bf5f61fc36924d0b806ec45ae09f8',
    searchFunction: function (helper) {
      if (helper.state.query === '') {
      } else {
        const currentFacets = helper.state.facets || [];

        if (!currentFacets.includes('categories')) {
          helper.setState(helper.state.addFacet('categories'));
        }

        helper.clearRefinements('categories');

        helper.addFacetRefinement('categories', getCurrentSearchTab() === 'edge' ? 'docs-edge' : 'docs-standard');

        helper.search();
      }
    }
  });

  const customSearchBox = connectSearchBox((renderOptions) => {
    const {
      refine
    } = renderOptions;

    refineRef = refine;
  });

  search.addWidget(
    customSearchBox({
      container: document.querySelector('#search_box')
    })
  );

  const customHits = connectHits((renderOptions, isFirstRender) => {
    if (!isFirstRender) {
      renderHits({
        hits: renderOptions.hits,
        query: renderOptions.results.query
      });
    }
  });

  search.addWidget(
    customHits({
      container: document.querySelector('#hits')
    })
  );

  search.start();
})
