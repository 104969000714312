import { expandCollapsedSection } from "./functions";

$(() => {
  const canShowTabs = $('body').attr('data-show-tabs') === 'true';

  if (!canShowTabs) {
    removeTabs();
    return;
  }

  $('.nav-tabs').children('.nav-item').each(function(index) {
    $(this).bind('switchActiveTab', function() {
      switchActiveTab(this);
    });

    if (index === 0) {
      const tabPanelContentId = $(this).attr('data-tabpanel-id');
      $(document).ready(() => expandOnlyOneSectionInTab(tabPanelContentId));
    }
  });
});

export function switchActiveTab(activeTab$) {
  $(activeTab$).siblings('.nav-item.active').removeClass('active');
  $(activeTab$).addClass('active');

  const tabPanelContentId = $(activeTab$).attr('data-tabpanel-id');
  $('.tab-pane').removeClass('active show');
  $('#' + tabPanelContentId).addClass('active show');

  expandOnlyOneSectionInTab(tabPanelContentId);
  $(window).scrollTop(0);
}

function expandOnlyOneSectionInTab(tabPanelContentId) {
  const collapsibleSections = $('#' + tabPanelContentId).find('.collapsible-section');
  if (collapsibleSections.length === 1) {
    const [section] = collapsibleSections;
    const toggle = $(section).find('.collapsible-toggle');

    // check if the section is collapsed
    if ($(toggle).hasClass('hevo-plus')) {
      expandCollapsedSection(toggle);
    }
  }
}

function removeTabs() {
  // Remove navigation
  $('#nav-tabs').remove();

  // Remove each inner content wrapper
  $('.tab-pane').each(function() {
    const content$ = $(this).children();
    $(this).replaceWith(function () {
      return $(content$, this);
    });
  });

  // Remove outer wrapper
  $('.tab-content').replaceWith(function () {
    const innerContent$ = $(this).children();
    return $(innerContent$, this);
  });
}