$(() => {
  const upcomingFeaturesList = $('.upcoming-feature-list-container').children();

  $('.select-input-container').click(function (event) {
    event.stopPropagation();
    $('.options-container').toggleClass('active');
  })

  $('.option').each(function () {
    $(this).click(function (event) {
      event.stopPropagation();

      const optionText = $(this).children('label').html();
      const optionValue = $(this).children('input').attr('value');

      $('.select-input-container > .select-input-container__left > .filter-text').html(optionText);
      $(this).addClass('selected');

      $('.options-container').removeClass('active');
      $(this).siblings().removeClass('selected');

      applyFilter(upcomingFeaturesList, optionValue);
    })
  });

  detectClickOutsideDropdown();
});

function detectClickOutsideDropdown() {
  $(document).click(function (event) {
    event.stopPropagation();
    const $optionsContainer = $('.options-container');

    //check if click was outside the options container
    if (!$(event.target).closest('.options-container').length) {
      $optionsContainer.removeClass('active')
    }
  });
}

function applyFilter($list, filter) {
  switch (filter) {
    case 'ASCENDING':
    case 'DESCENDING':
      $list.sort(function (a, b) {
        const titleA = getFeatureTitle(a);
        const titleB = getFeatureTitle(b);

        if (filter === 'ASCENDING') {
          return titleA > titleB ? 1 : -1;
        }

        return titleB > titleA ? 1 : -1;
      }).appendTo('.upcoming-feature-list-container');
      break;
    case 'STATUS':
      $list.sort(function(a, b) {
        const sortOrderA = getFeatureTagSortOrder(a);
        const sortOrderB = getFeatureTagSortOrder(b);

        if (sortOrderA !== sortOrderB) {
          return sortOrderA > sortOrderB ? 1 : -1
        } else {
          const titleA = getFeatureTitle(a);
          const titleB = getFeatureTitle(b);

          return titleA > titleB ? 1 : -1;
        }
      }).appendTo('.upcoming-feature-list-container');
      break;
  }
}

function getFeatureTitle(ele$) {
  return $(ele$).find('.feature-title').html().trim();
}

function getFeatureTagSortOrder(ele$) {
  return $(ele$).find('.feature-tag').attr('data-sort-order');
}