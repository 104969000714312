let terms = [];

class TermTooltipContent {
  constructor(contentString, $tooltipTrigger) {
    this.contentString = contentString;
    this.$node = null;
    this.$tooltipTrigger = $tooltipTrigger;
    this.expanded = false;
    this.threshold = 50;
    this.renderView();
  }

  listenEvents() {
    this.$node.find('.see-more').on('click', () => {
      this.expanded = true;
      this.renderView();
    });
  }

  renderView() {
    const $tooltipContent = $(this.$tooltipTrigger.data('bs.tooltip').tip);

    const $tooltipInner = $($tooltipContent.find('.tooltip-inner')[0]);

    $tooltipInner.empty();

    this.$node = $(document.createElement('div'));
    this.$node.html(this.contentString);


    $tooltipInner.append(this.$node);

    this.$tooltipTrigger.tooltip('update');

    const seeMoreContent = '<div class="see-more-container"><span class="see-more">See More</span></div>'

    if (!this.expanded && $tooltipInner.get(0).offsetHeight < $tooltipInner.get(0).scrollHeight) {
      this.$node.append(seeMoreContent);
    }

    if (this.expanded) {
      $tooltipInner.addClass('expanded');
    }

    this.listenEvents();
  }

  getNode() {
    return this.$node;
  }
}

class TermTooltipView {
  constructor($trigger) {
    this.$trigger = $trigger;
    this.termTitle = $trigger.data('for');
    this.defaultTooltipContent = '...';
    this.term = null;
    this.tooltipVisible = false;
    this.listenEvents();
  }

  getTermFromTitle() {
    return terms.find((term) => term.title.toLowerCase() === this.termTitle.toLowerCase())
  }

  getTermContentFromTitle() {
    return this.getTermFromTitle().content;
  }

  getTerms() {
    this.fetchingContent = true;
    this.contentFetchError = null;
    this.updateView();

    $.get('/terms.json').done((data) => {
      this.fetchingContent = false;
      terms = data;
      this.term = this.getTermFromTitle();

      this.updateView();
    }).fail(() => {
      this.fetchingContent = false;
      this.contentFetchError = 'Couldn\'t fetch term definition';
      this.updateView();
    });
  }

  listenEvents() {
    this.$trigger.hover(() => {
      clearTimeout(this.timeout);
      if (this.tooltipVisible) {
        return;
      }

      this.tooltipVisible = true;

      if (!terms.length) {
        this.getTerms();
      } else {
        this.term = this.getTermFromTitle();
      }

      this.updateView();
    }, () => {
      this.hideTooltipWithTimeout();
    });
  }

  hideTooltipWithTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.tooltipVisible = false;
      this.updateView();
    }, 300);
  }

  updateView() {
    if (this.tooltipVisible) {
      this.showTooltip();
    } else {
      this.hideTooltip();
    }
  }

  showTooltip() {
    this.$trigger.tooltip('dispose');

    let title = this.defaultTooltipContent;

    this.$trigger.tooltip({
      html: true,
      title: title,
      trigger: 'manual',
      sanitize: false,
      placement: 'auto',
      template: '<div class="tooltip glossary-term-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    });

    this.$trigger.tooltip('show');

    this.$trigger.on('shown.bs.tooltip', () => {
      const $tooltipContent = $(this.$trigger.data('bs.tooltip').tip);

      if (this.term?.content) {
        new TermTooltipContent(this.term.content, this.$trigger);
      }

      $tooltipContent.hover(() => {
        clearTimeout(this.timeout);
      }, () => {
        this.hideTooltipWithTimeout();
      });
    })
  }

  hideTooltip() {
    this.$trigger.tooltip('hide');
  }
}


$(document).ready(function() {
  $('[data-toggle="glossary-tooltip"]').each(function() {
    new TermTooltipView($(this));
  });
});
