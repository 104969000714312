$(() => {
  _appendIconInNotesContainer();
});

/**
 * @private
 */
function _appendIconInNotesContainer() {
  const notesContainerTypes = [
    {
      class: 'info',
      iconClass: 'light'
    },
    {
      class: 'warning',
      iconClass: 'warning'
    },
    {
      class: 'error',
      iconClass: 'warning'
    }
  ];

  $(".note-container").each(function() {
    const elem$ = $(this);

    notesContainerTypes.forEach((containerType) => {
      if (elem$.hasClass(containerType.class + '-note-container')) {
        const containerTypeIcon$ = $(`<span class='hevo-docs-icon hevo-${containerType.iconClass}'></span>`);
        const wrapperDiv$ = $('<div class="note-wrapper"></div>');

        elem$.wrapInner(wrapperDiv$);
        elem$.prepend(containerTypeIcon$);
      }
    });
  });
}
