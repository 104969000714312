jQuery.fn.highlight = function(pat) {
  function innerHighlight(node, pat) {
    var skip = 0;

    if (node.nodeType === 3 && node.parentElement.localName !== 'tspan') {
      const pos = node.data.toUpperCase().indexOf(pat);

      if (pos >= 0) {
        const spanNode = document.createElement('span');
        spanNode.className = 'highlighted-search-term';

        const middleBit = node.splitText(pos);
        middleBit.splitText(pat.length);
        const middleClone = middleBit.cloneNode(true);

        spanNode.appendChild(middleClone);
        middleBit.parentNode.replaceChild(spanNode, middleBit);
        skip = 1;
      }
    } else if (node.nodeType === 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
      for (let i = 0; i < node.childNodes.length; ++i) {
        i += innerHighlight(node.childNodes[i], pat);
      }
    }

    return skip;
  }
  
  return this.each(function() {
    innerHighlight(this, pat.toUpperCase());
  });
};
