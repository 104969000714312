$(() => {
  $(window).on('scroll', () => {
    _updateScrollToTopBtn();
  });

  _handleScrollToTopClick();
});

function _updateScrollToTopBtn() {
  const scrollTopPos = $(window).scrollTop(),
    documentHeight = $(document).height();

  // Show the scrollToTop btn when 25% doc is scrolled
  if (scrollTopPos >= (documentHeight / 4) ) {
    $('.scroll-top-btn').removeClass('d-none');
  } else {
    $('.scroll-top-btn').addClass('d-none');
  }
}

function _handleScrollToTopClick() {
  $('.scroll-top-btn').on('click', () => {
    $(window).scrollTop(0);
  });
}
