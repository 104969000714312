import { expandIfCollapsed } from "./functions";

class ScrollToAnchorOnLoad {
  constructor() {
    this.didUpdateHandler = this.didUpdateHandler.bind(this);
    this.registerHandlers = this.registerHandlers.bind(this);
  }

  didUpdateHandler(event) {
    // Handle vertical scroll position of new content
    if (window.location.hash) {
      const el$ = $(window.location.hash).get(0);

      if (el$) {
        expandIfCollapsed(window.location.hash);
        el$.scrollIntoView();
      }
    } else {
      $(window).scrollTop(0);
    }
  }

  registerHandlers() {
    $(document).on('page.didUpdate', this.didUpdateHandler);
  }
}

export default ScrollToAnchorOnLoad;
