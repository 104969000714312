$(() => {
  _handleChatSupportClick();
});

function _handleChatSupportClick() {
  $('.chat-support-trigger').on('click', () => {
    if (typeof Intercom !== "undefined") {
      Intercom('show');
    }
  });
}
