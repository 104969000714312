import 'lightgallery';
import 'lg-zoom';

$(() => {
  _prepareImagesForZoom();
  _enableImageZoomProperties();
});

function _prepareImagesForZoom() {
  $('.doc-section img').filter(function() {
    if ($(this).parent().hasClass('node-image') || $(this).hasClass('upcoming-feature-logo')) {
      return false;
    }

    return true;
  }).each(function() {
    const imgUrl = $(this).attr('src');
    $(this).wrap('<a href="'+imgUrl+'" class="lightgallery-link"></a>');
  });
}

function _enableImageZoomProperties() {
  $('.lightgallery-link').lightGallery({
    selector: 'this',
    counter: false,
    getCaptionFromTitleOrAlt: true
  });
}
