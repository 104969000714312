import { isNumber } from "lodash";
import { switchActiveTab } from "./tabs";

/**
 * expand the collapsed section
 *
 * @param {any} elem
 */

export function expandCollapsedSection(elem) {
  $(elem)
    .closest(":header")
    .next(".collapsible-content")
    .show();
  $(elem).removeClass("collapsed");
  $(elem).toggleClass("hevo-plus hevo-minus");
}

/**
 * collapse the expanded section
 *
 * @param {*} elem
 */
export function collapseExpandedSection(elem) {
  $(elem)
    .closest(":header")
    .next(".collapsible-content")
    .hide();
  $(elem).addClass("collapsed");
  $(elem).toggleClass("hevo-plus hevo-minus");
}

/**
 * collapse all the expanded sections
 */
export function collapseAllExpandedSections() {
  $("h2")
    .filter((i, el) => {
      const $el = $(el);

      return (
        $el.hasClass("collapsible-section") ||
        $el.hasClass("collapsible-section-dash")
      );
    })
    .each((i, el) => {
      const $el = $(el);
      const collapsibleToggle$ = $el.find(".collapsible-toggle");

      if (!collapsibleToggle$.hasClass("collapsed")) {
        collapseExpandedSection(collapsibleToggle$);
      }
    });
}

/**
 * expand the collapsed section
 *
 * @param {*} collapsedElemId id of the header elem to be expanded with leading #
 */
export function expandIfCollapsed(collapsedElemId) {
  let collapseToggle$;

  // h2 tag have the toggle btn
  if ($(collapsedElemId).is("h2")) {
    collapseToggle$ = $(collapsedElemId).find(".collapsible-toggle");
  } else if ($(collapsedElemId).is("h3")) {
    const parentCollapsedContent$ = $(collapsedElemId).closest(
      ".collapsible-content"
    );

    // If h3 element is wrapped in a collapsed content
    if (parentCollapsedContent$) {
      const elemPrevToParentCollapsedContent$ = parentCollapsedContent$.prev();

      // verify if the parent is a collapsed section and is a h2
      if (
        elemPrevToParentCollapsedContent$.is("h2") &&
        elemPrevToParentCollapsedContent$.hasClass("collapsible-section")
      ) {
        const collapsedH2Id = elemPrevToParentCollapsedContent$.attr("id");
        expandIfCollapsed("#" + collapsedH2Id);
      }
    }

    collapseToggle$ = $(collapsedElemId).find(".collapsible-toggle");
  } else {
    const collapsedContent$ = $(collapsedElemId).closest(
      ".collapsible-content"
    );
    collapseToggle$ = collapsedContent$
      .prev(".collapsible-section")
      .find(".collapsible-toggle");
  }

  // if collapsed
  if (collapseToggle$ && collapseToggle$.hasClass("collapsed")) {
    expandCollapsedSection(collapseToggle$);
  }
}

export function extractFragmentFromURL(link) {
  if (link.startsWith("/")) {
    link = window.location.origin + link;
  }

  const url = new URL(link);
  return url.hash || null;
}

export function getSectionIdClosestToScroll() {
  const scrollTop = $(window).scrollTop();
  let currentMatch;

  $(":header").each(function() {
    const id = $(this).attr("id"),
      topPosition = $(this).offset().top;

    if (id && topPosition > 0 && scrollTop >= topPosition) {
      currentMatch = id;
    }
  });

  return currentMatch;
}

export function sanitiseDOMForHelpNotes() {
  // Remove unwanted padding
  $(".doc-wrap, .main-wrap").removeClass("pb-5");
  $(".scroll-top-btn, .footer-dash").addClass("d-none");
  $(".blur-overlay.top-overlay").remove();

  $(".doc-section ul.new-design-ul").css({ "margin-bottom": 0 });
  $("body").css({ padding: "1.75rem 3.1rem 0" });

  // Remove all the img elements as we don't want to render these in help notes
  $(".lightgallery-link")
    .parent("p")
    .addClass("d-none");
}

/**
 * Highlight the list item title
 * for better visibility when we auto scroll in dashboard sbs
 *
 * @param parentEle
 * @param duration  remove highlight after duration
 * */
export function highlightListItemTitle(parentEle, duration) {
  const ele$ = $(parentEle)
    .find("p strong")
    .get(0);

  if (ele$) {
    $(ele$)
      .addClass("highlighted-search-term")
      .css("color", "black");

    if (duration && isNumber(duration)) {
      setTimeout(() => {
        $(ele$)
          .removeClass("highlighted-search-term")
          .css("color", "var(--text-color)");
      }, duration);
    }
  }
}

export function areTabsRendered() {
  return $("body").attr("data-show-tabs") === "true";
}

function getActiveTab() {
  return $("#nav-tabs")
    .children(".nav-item.active")
    .html()
    .trim();
}

/**
 * Since we support both tabs and auto scroll,
 * we need to make sure that auto scrolling works when
 * tabs are rendered. Auto scroll link can be in different tab than
 * user currently in, so we need to switch to that tab and then auto scroll to link.
 *
 * @param tab: string
 * */
export function switchToTab(tab) {
  const activeTab = getActiveTab();

  if (activeTab === tab) {
    return;
  }

  const tab$ = $("div").find(`[data-tab-name='${tab}']`);

  if (tab$) {
    switchActiveTab(tab$);
  }
}

/**
 * Scroll to section with additional functionality to
 * adjust scrollOffset, switch tabs if necessary or
 * highlight list item titles.
 *
 * @param link: string
 * @param scrollOffset?: number
 * @param highlightTitle?: boolean
 * */
export function skipToSection({
  link,
  scrollOffset = 0,
  highlightTitle = false,
}) {
  if (link) {
    const link$ = $(link);

    if (areTabsRendered()) {
      const tabContentPanelId = link$
        .closest(".tab-pane")
        .attr("id")
        .trim();
      const tab = $("div")
        .find(`[data-tabpanel-id='${tabContentPanelId}']`)
        .html()
        .trim();

      if (tab) {
        switchToTab(tab);
      }
    }

    expandIfCollapsed(link);

    $("html, body").animate(
      {
        scrollTop: link$.offset().top + scrollOffset,
      },
      800,
      "swing"
    );

    if (highlightTitle) {
      highlightListItemTitle($(link), 3000);
    }
  }
}

export function preventParentScroll(selector$) {
  selector$.on("DOMMouseScroll mousewheel", function(ev) {
    const $this = $(this),
      scrollTop = $this.scrollTop(),
      scrollHeight = $this.prop("scrollHeight"),
      height = $this.innerHeight(),
      delta = (ev.type == 'DOMMouseScroll' ?
          ev.originalEvent.detail * -40 :
          ev.originalEvent.wheelDelta),
      up = delta > 0;

    const prevent = function() {
      ev.stopPropagation();
      ev.preventDefault();
      ev.returnValue = false;
      return false;
    }

    if (!up && -delta > scrollHeight - height - scrollTop) {
      // Scrolling down, but this will take us past the bottom.
      $this.scrollTop(scrollHeight);
      return prevent();
    } else if (up && delta > scrollTop) {
      // Scrolling up, but this will take us past the top.
      $this.scrollTop(0);
      return prevent();
    }
  });
}
