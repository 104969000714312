$(() => {
  const infographicsSectionLink$ = $('.infographics-section-link');

  if (infographicsSectionLink$.length) {
    infographicsSectionLink$.each(function() {
      const url = $(this).attr('data-url');

      if (url) {
        $(this).on('click', function() {
          window.open(url, '_blank');
        });

        const fillColor = $(this).attr('fill');

        $(this).mouseenter(function(e) {
          $(this).attr('fill', '#693EE0');
          $(this).css('text-decoration', 'underline')
        });

        $(this).mouseleave(function (e) {
          $(this).attr('fill', fillColor);
          $(this).css('text-decoration', 'none')
        });
      }
    });
  }
});
