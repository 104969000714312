$(() => {
  console.log($('[data-sidebar-new-badge]'));

  $('[data-sidebar-new-badge]').each((i, badge) => {
    const visibilityTimeout = $(badge).data('visibilityTimeout');

    if (new Date().getTime() < visibilityTimeout) {
      $(badge).removeClass('d-none');
    }
  })
});
