import {debounce} from 'lodash';

const ESCAPE = 27;
const ENTER = 13;
const UP_KEY = 38;
const DOWN_KEY = 40;

export function getSearchInput({
  refine
}) {
  const $hits = $('#hits');
  const container = document.querySelector('#search_box');
  const $searchWrap = $hits.closest('.navbar-search');
  const $clearSearch = $searchWrap.find('.clear-search');
  const $loadingIndicator = $searchWrap.find('.loading-indicator');
  const $searchBackdrop = $('#search_backdrop');
  const $searchToggler = $('.search-toggler');
  const $searchInput = $searchWrap.find('input');

  let $list;
  let currentSearchTab = 'standard';
  let activeItemIndex = -1;
  let totalItems = 0;
  let groupCountIndex = -1;

  $searchToggler.click(function () {
    $searchWrap.slideToggle();
    if ($searchWrap.hasClass('opened')) {
      $searchInput.blur();
      $searchWrap.removeClass('opened');
    } else {
      $searchWrap.addClass('opened');
      $searchInput.focus();
    }
  });

  $(document).on('click', '.search-results .list-group-item', function (e) {
    e.preventDefault();
    openItem($(this));
  });

  const escapeHtml = unsafe => {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  };

  const getHitDataToRender = (hit) => {
    let matchingValue = '';

    const breadcrumbs = hit.breadcrumbs_data.slice(1, hit.breadcrumbs_data.length);

    const breadcrumbsPath = breadcrumbs.reduce((subPath, breadcrumb) => {
      return `${subPath}/${breadcrumb.name}`;
    }, '');

    let firstBreadcrumb;
    let lastBreadcrumb;

    if (breadcrumbs.length === 0) {
      firstBreadcrumb = 'Home';
      lastBreadcrumb = ' /';
    } else {
      firstBreadcrumb = breadcrumbs[0].name;
      lastBreadcrumb = (breadcrumbs.length === 2 ? ' / ... / ' : ' / ')  + breadcrumbs[breadcrumbs.length - 1].name;
    }

    if (hit._highlightResult?.title?.matchLevel === 'full') {
      matchingValue = hit.title;
    } else if (hit._highlightResult?.content?.matchLevel === 'full') {
      matchingValue = hit.content.split('\n')[0];
    }

    matchingValue = matchingValue.replace(/_/g, '=').replace(/ /g, '_');

    return {
      matchingValue,
      firstBreadcrumb,
      breadcrumbsPath,
      lastBreadcrumb
    }
  }

  const renderHit = (hit, index) => {
    const renderData = getHitDataToRender(hit);
    const search_term = $('#search_input').val();
    return `
      <a data-search-term="${search_term}" data-search-result-term="${renderData.matchingValue}" href="${escapeHtml(
      hit.url
    )}" class="list-group-item list-group-item-action ${(activeItemIndex === index && groupCountIndex === index) ? 'list-group-item-active' : ''}">
      <div class="mb-1 breadcrumbs"><span title="${renderData.breadcrumbsPath}">
        ${renderData.firstBreadcrumb} ${renderData.lastBreadcrumb}</span></div>
      ${hit._snippetResult?.content?.value || hit._snippetResult?.title || ''}
      </a>
    `;
  };

  const trackQueryResult = debounce((query, resultCount) => {
    if (window.srtURL === 'search_result_tracking_url_value') {
      return;
    }

    fetch(window.srtURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event: 'search_result',
        properties: {
          query: query,
          result_count: resultCount
        }
      })
    })
  }, 1000);

  const renderHits = (renderOptions) => {
    $loadingIndicator.attr('hidden', true);

    const {hits, query} = renderOptions;

    if (query) {
      trackQueryResult(query, hits.length);
    }

    totalItems = hits.length;
    activeItemIndex = 0;

    const resultsContainer = document.querySelector('.search-results');

    if (!hits.length) {
      resultsContainer.innerHTML =
        '<div class="list-group-item">No results</div>';
    } else {
      resultsContainer.innerHTML = `${hits
        .sort((a, b) => {
          const x = a.breadcrumbs_data[1]?.name;
          const y = b.breadcrumbs_data[1]?.name;

          const sortTerm = 'Hevo Activate';

          if (x !== sortTerm && y !== sortTerm) {
            return 0;
          }

          if (x === sortTerm) {
            return 1;
          }

          return -1;
        })
        .map(renderHit)
        .join('')}`;
    }

    $list = $('.list-group');
  };

  const renderSearchBox = () => {
    const input = container.querySelector('input');
    const clearSearch = container.querySelector('.clear-search');

    input.addEventListener('input', event => {
      onQuery(event.target.value);
    });

    input.addEventListener('keydown', event => {
      if (event.keyCode === ENTER) {
        event.preventDefault();
        openActiveItem();
      }
    });

    input.addEventListener('keyup', event => {
      if (event.keyCode === ESCAPE) {
        hideDropdown();
      }

      if (event.keyCode === UP_KEY && totalItems > 0) {
        activatePrevItem();
      }

      if (event.keyCode === DOWN_KEY && totalItems > 0) {
        activateNextItem();
      }
    });

    $searchBackdrop.on('click', function (event) {
      hideDropdown();
    });

    input.addEventListener('click', event => {
      const query = event.target.value;

      if ($hits.hasClass('d-none')) {
        onQuery(query);
      }
    });

    clearSearch.addEventListener('click', event => {
      input.value = '';
      hideDropdown();
    });

    $('[data-search-tab-trigger]').click(function() {
      currentSearchTab = $(this).data('searchTabTrigger');
      $('[data-search-tab-trigger]').removeClass('active');
      $(this).addClass('active');
      $('.search-results').html('');
      $loadingIndicator.removeAttr('hidden');
      refine(input.value);
    });
  };

  const hideDropdown = () => {
    $searchWrap.removeClass('hasvalue');
    $hits.addClass('d-none');
    $searchBackdrop.addClass('d-none');
    $clearSearch.attr('hidden', true);
    $loadingIndicator.attr('hidden', true);
  }

  const activateNextItem = () => {
    activeItemIndex += 1;

    if (activeItemIndex >= totalItems) {
      activeItemIndex = 0;
    }

    updateActiveItemClasses();
    scrollToActiveItem('down');
  };

  const activatePrevItem = () => {
    activeItemIndex -= 1;

    if (activeItemIndex < 0) {
      activeItemIndex = totalItems - 1;
    }

    updateActiveItemClasses();
    scrollToActiveItem('up');
  };

  const scrollToActiveItem = (dir) => {
    const _activeItem = getActiveItem().get(0);

    if (activeItemIndex === 0) {
      $list.scrollTop(0);
      return;
    }

    const activeItemTop = _activeItem.offsetTop;
    const activeItemBottom = activeItemTop + _activeItem.offsetHeight;

    const listScrollTop = $list.get(0).scrollTop;
    const listHeight = $list.get(0).offsetHeight;

    if (activeItemIndex === totalItems - 1) {
      $list.scrollTop(activeItemBottom - listHeight);
      return;
    }

    if (dir === 'up' && activeItemTop < listScrollTop) {
      $list.scrollTop(activeItemTop);
    } else if (dir === 'down' && activeItemBottom > listScrollTop + listHeight) {
      $list.scrollTop(activeItemBottom - listHeight);
    }
  };

  const updateActiveItemClasses = () => {
    $('.list-group-item').removeClass('list-group-item-active');
    getActiveItem().addClass('list-group-item-active');
  };

  const openActiveItem = () => {
    if (activeItemIndex < 0 || totalItems == 0) {
      return;
    }

    const $activeItem = getActiveItem();

    openItem($activeItem);
  };

  const getActiveItem = () => {
    return $('.list-group-item').eq(activeItemIndex);
  };

  const openItem = ($element) => {
    localStorage.setItem('search_term', $element.data('searchTerm'));
    localStorage.setItem('search_result_term', $element.data('searchResultTerm'));
    window.location.href = $element.attr('href');
  }

  const onQuery = (query) => {
    totalItems = 0;
    activeItemIndex = -1;

    if (query === '') {
      hideDropdown();
    } else {
      $searchWrap.addClass('hasvalue');
      $hits.removeClass('d-none');
      $searchBackdrop.removeClass('d-none');
      $clearSearch.removeAttr('hidden');
      $loadingIndicator.removeAttr('hidden');
      document.querySelector('.search-results').innerHTML = '';
    }

    refine(query);
  }

  const getCurrentSearchTab = () => {
    return currentSearchTab;
  }

  renderSearchBox();

  return {
    onQuery,
    getCurrentSearchTab,
    renderHits
  }
}
